import React from 'react';
import profilePic from '../assets/profile-pic.jpeg';

function About({ setActiveSection }) {
  const handleViewWork = (e) => {
    e.preventDefault();
    setActiveSection('projects');
    const projectsSection = document.querySelector('.projects');
    if (projectsSection) {
      projectsSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleContact = (e) => {
    e.preventDefault();
    setActiveSection('contact');
    const contactSection = document.querySelector('.contact');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error('Contact section not found');
    }
  };

  return (
    <section className="about">
      <h2>About Me</h2>
      <div className="about-content">
        <div className="profile-pic-container">
          <img src={profilePic} alt="Aum Patel" className="profile-pic" />
        </div>
        <div className="about-text">
          <p>Hello! I'm Aum Patel, a passionate DevSecOps engineer with a knack for securing and optimizing application deployments.</p>
          <p>With hands-on experience in tools like Docker, Kubernetes, and Jenkins, I specialize in bridging the gap between development and operations while maintaining robust security practices.</p>
          <p>My journey in tech has led me from cryptography analysis to implementing cutting-edge security measures for cloud infrastructures. I'm always excited to take on new challenges and contribute to innovative projects!</p>
          <div className="about-cta">
            <a href="#contact" className="cta-button" onClick={handleContact}>Get in Touch</a>
            <a href="#projects" className="cta-button secondary" onClick={handleViewWork}>View My Work</a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;