import React from 'react';

function Education() {
  return (
    <section>
      <h2>Education</h2>
      <h3>SRM UNIVERSITY, Kattankulathur, Chennai</h3>
      <p>B.Tech - 8.89 CGPA (2020 - 2024)</p>
      <h3>GREEN VALLEY HIGH SCHOOL, Vadodara, Gujarat</h3>
      <p>12th (2018 - 2020)</p>
    </section>
  );
}

export default Education;