import React from 'react';

function Skills() {
  return (
    <section>
      <h2>Technical Skills</h2>
      <ul>
        <li>Programming Languages: Python, C++, SQL, bash</li>
        <li>Skills: Linux, Git, Docker, Podman, Kubernetes, Jenkins, Terraform, Networking, Cryptography, VAPT, AWS, GCP</li>
        <li>Tools: Sonarqube, Metasploit, PFsense, Splunk, Trivy, Snort, Zap</li>
      </ul>
    </section>
  );
}

export default Skills;