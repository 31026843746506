import React, { useState } from 'react';

function Contact() {
  const [status, setStatus] = useState('');

  const submitForm = (ev) => {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        setStatus("SUCCESS");
      } else {
        setStatus("ERROR");
      }
    };
    xhr.send(data);
  }

  return (
    <section className="contact">
      <h2>Contact Me</h2>
      <form
        onSubmit={submitForm}
        action="https://formspree.io/f/xblrjeob"
        method="POST"
      >
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" required />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" required />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea id="message" name="message" required></textarea>
        </div>
        {status === "SUCCESS" ? <p>Thanks for your message! <p>Message Sent Successfully</p></p> :
          <button type="submit">Send Message</button>}
        {status === "ERROR" && <p>Oops! There was an error.</p>}
      </form>
    </section>
  );
}

export default Contact;