import React, { useState } from 'react';

function Projects() {
  const [activeProject, setActiveProject] = useState(null);

  const projects = [
    {
      title: "QuickkMail",
      description: "A generic notification system for bulk email sending.",
      details: "Designed and developed QuickkMail, allowing users to send up to 500 bulk text emails daily based on pre-defined templates. Implemented email drafting, compilation, prioritization, and scheduling.",
      skills: ["Web Development", "Email APIs", "Project Management"],
      github: "https://github.com/patel-aum/QuickkMail"
    },
    {
      title: "VitalityVaults",
      description: "High-availability healthcare app using Docker and Kubernetes.",
      details: "Ensured uninterrupted access for doctors, implemented detection and prevention of cascading effects on microservices, and utilized telemetry data of nodes to save costs.",
      skills: ["Docker", "Kubernetes", "Microservices"],
      github: "https://github.com/patel-aum/VitalityVaults"
    },
    {
      title: "ROT",
      description: "Caesar cipher implementation in Linux using Bash.",
      details: "Developed a tool for text encryption and decryption, gaining experience in programming, algorithm development, and Linux system administration.",
      skills: ["Cryptography", "Bash Scripting", "Linux"],
      github: "https://github.com/patel-aum/ROT"
    },
    {
      title: "Radio Frequency Project",
      description: "FM frequency modification using Raspberry Pi 3.",
      details: "Modified FM frequencies in a short area, broadcasting personal songs or voice over current frequencies using Raspberry Pi 3.",
      skills: ["Cybersecurity", "Raspberry Pi", "Radio Frequency"]
    }
  ];

  return (
    <section className="projects">
      <h2>Projects</h2>
      <div className="project-grid">
        {projects.map((project, index) => (
          <div 
            key={index} 
            className={`project-card ${activeProject === index ? 'active' : ''}`}
            onClick={() => setActiveProject(activeProject === index ? null : index)}
          >
            <h3>{project.title}</h3>
            <p>{project.description}</p>
            {activeProject === index && (
              <div className="project-details">
                <p>{project.details}</p>
                <div className="skills">
                  {project.skills.map((skill, i) => (
                    <span key={i} className="skill-tag">{skill}</span>
                  ))}
                </div>
                {project.github && (
                  <a href={project.github} target="_blank" rel="noopener noreferrer">GitHub</a>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
}

export default Projects;