import React, { useState } from 'react';
import About from './components/About';
import Experience from './components/Experience';
import Projects from './components/Projects';
import Skills from './components/Skills';
import Education from './components/Education';
import Contact from './components/Contact';
import Navbar from './components/Navbar';
import './App.css';

function App() {
  const [activeSection, setActiveSection] = useState('about');

  return (
    <div className="App">
      <Navbar setActiveSection={setActiveSection} />
      <main>
        <section className="about">
          <About setActiveSection={setActiveSection} />
        </section>
        <section className="experience">
          <Experience />
        </section>
        <section className="projects">
          <Projects />
        </section>
        <section className="skills">
          <Skills />
        </section>
        <section className="education">
          <Education />
        </section>
        <section className="contact">
          <Contact />
        </section>
      </main>
    </div>
  );
}

export default App;
