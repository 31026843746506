import React from 'react';

function Experience() {
  return (
    <section>
      <h2>Work Experience</h2>
      <h3>DevSecOps Intern - Affinys AI, Bangalore</h3>
      <p>Jan 2024 - June 2024</p>
      <ul>
        <li>Deployed products on on-prem bank servers using Docker, Podman, and Docker-compose.</li>
        <li>Managed three clients, ensuring smooth operations on Linux platforms.</li>
        <li>Implemented pfSense firewall, OpenVPN, and Snort IDS with alerts.</li>
        <li>Configured SonarQube as a SAST tool in the Jenkins pipeline.</li>
      </ul>
      <h3>SAS Intern - Department of Telecommunication, Bangalore</h3>
      <p>Dec 2022 - Feb 2023</p>
      <ul>
        <li>Analyzed international cryptographic standards for 4G and 5G telecom operators.</li>
        <li>Prepared a comprehensive report with recommendations for NCCS on current cryptographic standards.</li>
        <li>Gained hands-on experience in Vulnerability Assessment and Penetration Testing (VAPT) using Metasploit Pro.</li>
      </ul>
    </section>
  );
}

export default Experience;