import React, { useState, useEffect } from 'react';
import './Navbar.css';

function Navbar({ setActiveSection }) {
  const [scrolled, setScrolled] = useState(false);
  const [menuActive, setMenuActive] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  const handleNavClick = (section) => {
    setActiveSection(section);
    const sectionElement = document.querySelector(`.${section}`);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
    setMenuActive(false);
  };

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  return (
    <nav className={`navbar ${scrolled ? 'scrolled' : ''}`}>
      <div className="navbar-container">
        <a href="#home" className="navbar-logo" onClick={() => handleNavClick('home')}>
          Aum Patel
        </a>
        <div className={`nav-menu ${menuActive ? 'active' : ''}`}>
          {['about', 'experience', 'projects', 'skills', 'education', 'contact'].map((item) => (
            <li key={item} className="nav-item">
              <a href={`#${item}`} className="nav-link" onClick={() => handleNavClick(item)}>
                {item.charAt(0).toUpperCase() + item.slice(1)}
              </a>
            </li>
          ))}
        </div>
        <div className="hamburger-menu" onClick={toggleMenu}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
